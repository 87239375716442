"use client";
import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import HeroSection from "../components/hero-section";
import Navbar from "../components/navbar";
import ServicesSection from "../components/service-section";
import { Box, Grid, Image, Text } from "@chakra-ui/react";
import SectionImage from "../components/container";
import TwoColumn from "../components/container/twoColumn";
import Link from "next/link";
import fetchFromMongoDB from "@/utils/fetchFromMongo";
import updateDataFromRapidAPI from "@/utils/fetchFromRapid";
import { SkeletonCard } from "../components/loader/skeleton";
import config from "@/utils/config";
import FeaturedCard from "../components/cards/featuredCard";

const HomeIndex = ({serverData}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const features = [
    {
      title: "City-Specific Listings",
      description: "Access detailed listings of sump pump service providers in your area, complete with location details and contact information.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
          <circle cx="12" cy="10" r="3"></circle>
        </svg>
      ),
    },
    {
      title: "Wide Range of Services",
      description: "Find specialists offering a full spectrum of services, from installations to regular maintenance and repairs.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
          <path d="M20 12v10H4V12"></path>
          <path d="M2 7h20v5H2z"></path>
          <path d="M12 22V7"></path>
          <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
          <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
        </svg>
      ),
    },
    {
      title: "Customer Reviews",
      description: "Check out reviews from other customers to help you make an informed decision.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
      ),
    },
    {
      title: "Updated Regularly",
      description: "We keep our listings current, so you always have access to the most reliable information.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
          <polygon points="3 11 22 2 13 21 11 13 3 11"></polygon>
        </svg>
      ),
    },
    {
      title: "Updated Regularly",
      description: "We continually update our listings to ensure you get the most accurate and up-to-date information.",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
          <path d="M23 4v6h-6"></path>
          <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
        </svg>
      ),
    },
  ];

  const fetchDataMongo = async () => {
    try {
      const response = await serverData;
      
      if (response) {
        setData(response);  
      }
    } catch (error) {
      console.log(error, "unable to fetch the data from fetchDataMongo");
    } finally {
      setLoading(false);
    }
  };


  const updateData = async () => {
    try {
      const response = await updateDataFromRapidAPI();
      if (response) {
        setData(response.listingResponse);
      }
    } catch (error) {
      console.log(error, "unable to update the data from RapidAPI");
    }
  };

  useEffect(() => {
    fetchDataMongo();
  }, []);



  return (
    <>
      <div>
        <HeroSection />
        



        {/* List of cities */}
        <div className="w-full pb-16 bg-indigo-50 p-8">
          <h2 className="text-2xl text-center mb-2">
          Your Trusted Source for Sump Pump Services Across the USA

          </h2>
          <p className="text-center mb-4 mx-auto w-1/2">
          At Sump Pump Near Me, locating a reliable sump pump service in your city is quick and easy. Our platform features a thorough directory of service providers across the country. From regular maintenance to urgent repairs, you’ll find the experts you need to keep your sump pump in top working order.
          </p>
          <div className="flex items-center justify-center mt-10">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg max-w-screen-xl">
              <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 text-left text-blue-600">
                {loading ? (
                  <SkeletonCard />
                ) : data.length > 0 ? (
                  data.map((state, index) => {
                    const url = state ? `/${state.toLowerCase().replace(/\s+/g, "-")}` : "";

                    return (
                      <Link key={index} href={`${url}`}>
                        <span className="w-1/2 block p-2 text-blue-500 hover:underline capitalize whitespace-nowrap">
                         {state}
                        </span>
                      </Link>
                    );
                  })
                ) : (
                  <p className="text-center">No data available.</p>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 py-16">
          <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Why Choose Sump Pump Near Me?          </h2>
          <p className="text-center w-1/2 mx-auto mb-8">Using Sump Pump Near Me ensures you&apos;re connected with qualified sump pump professionals in your area. From emergency repairs to complete installations, our directory helps you find the right experts to protect your property from water damage.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {features.map((item, index)=> (
              <FeaturedCard 
                key={index}
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            ))}
          </div>
        </div>
        {/* <button onClick={updateData}>Update Data from RapidAPI</button> */}
        {/* <TwoColumn
          bgImage="/assets/imgs/electric-bg.jpg"
          firstColumn={
            <>
              <div className="w-full">
                <h1 className="text-white text-xl font-extrabold mb-4">
                  How to Find The Best Goodwill stores Near Me
                </h1>
                <p className="text-white">
                  Digital technology has made it convenient to find local
                  Goodwill stores. Instead of asking your friends or relatives for
                  a referral, now you can save much time and effort by just
                  searching for “Goodwill stores near me” online.
                </p>
                <p className="text-white">
                  The first solution that pops up in your mind might be Google
                  Search. Just type in “Goodwill stores near me” and then choose
                  from the list of results that show in the map. You may also
                  find online tools that offer services nearby and can help with
                  your electrical needs.
                </p>
                <p className="text-white">
                  With so many options provided, you may still need to spend
                  much time comparing and selecting the best Goodwill stores
                  company. To further simplify the process and address your
                  urgent needs, weve established this platform to give you the
                  easiest access to local Goodwill stores.
                </p>
              </div>
            </>
          }
          secondColumn={
            <>
              <div>
                <div className="w-full bg-cyan-600 h-4 rounded-t rounded-tr"></div>
                <div className="bg-white p-4">
                  <div className="rounded-lg text-white max-w-md mx-auto">
                    <h2 className="text-2xl font-bold mb-4 text-black">
                      Here is how you can find the best Goodwill stores near you
                      within 5 minutes:
                    </h2>
                    <ol className="list-decimal list-inside  text-black">
                      <li>
                        <span className="font-semibold text-black">
                          Step 1:
                        </span>{" "}
                        Navigate to our{" "}
                        <a
                          href="#"
                          className="text-blue-300 font-bold underline"
                        >
                          local Goodwill stores search engine
                        </a>{" "}
                        and enter a ZIP code or your city and state, then hit
                        the Start Search button.
                      </li>
                      <li>
                        <span className="font-semibold text-black">
                          Step 2:
                        </span>{" "}
                        In less than a minute, youll see a list of the
                        recommended Goodwill stores on the result page. Browse and
                        compare the Goodwill stores experts in your area.
                      </li>
                      <li>
                        <span className="font-semibold text-black">
                          Step 3:
                        </span>{" "}
                        Select the best Goodwill stores based on their location,
                        ratings, review, or other information we collected and
                        the quotes they propose.
                      </li>
                    </ol>
                    <p className="mt-4 text-black">
                      Thats it! You can get all the details in one place without
                      further hassle. If you need an immediate estimate of the
                      cost</p>
                  </div>
                </div>
              </div>
            </>
          }
        /> */}
      </div>
    </>
  );
};

export default HomeIndex;
