import Image from "next/image";
import Link from "next/link";
import { GiWaterMill } from "react-icons/gi";

const Navbar = () => {
  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-4">
            {/* <!-- logo --> */}
            <div>
              <Link
                href="/"
                className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900"
              >
                {/* <Image src='/assets/imgs/pump-sump-logo.svg' alt="Pump sump logo" width='24' height='24' /> */}
                <GiWaterMill />
                <span className="font-bold ml-2">Sump Pump Finder</span>
              </Link>
            </div>

            {/* <!-- primary nav --> */}
            {/* <div className="hidden md:flex items-center space-x-1">
              <a href="#" className="py-5 px-3 text-gray-700 hover:text-gray-900">
                Features
              </a>
              <a href="#" className="py-5 px-3 text-gray-700 hover:text-gray-900">
                Pricing
              </a>
            </div> */}
          </div>

          {/* <!-- secondary nav --> */}
          <div className="hidden md:flex items-center space-x-1">
            <a
              href="tel:+18317313360"
              className="py-2 px-3 bg-blue-500 hover:bg-blue-500 text-white rounded transition duration-300"
            >
              +18317313360
            </a>
          </div>

          {/* <!-- mobile button goes here --> */}
          <div className="md:hidden flex items-center">
            {/* <button className="mobile-menu-button">
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button> */}
            <a
              href="tel:+18317313360"
              className="py-1 px-3 bg-blue-500 hover:bg-blue-500 text-white rounded transition duration-300"
            >
              +18317313360
            </a>
          </div>
        </div>
      </div>

      {/* <!-- mobile menu --> */}
      <div className="mobile-menu hidden md:hidden">
        <Link href="#" className="block py-2 px-4 text-sm hover:bg-gray-200">
          Features
        </Link>
        <Link href="#" className="block py-2 px-4 text-sm hover:bg-gray-200">
          Pricing
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
